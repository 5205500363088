export default {
	name: 'StoreHelper',
	methods: {
        setLocalStorageString(key,value) {
            localStorage.setItem(key, value);
        },
		getLocalStorageString(key) {
			return localStorage.getItem(key);
        },
		setLocalStorageObject(key,value) {
            localStorage.setItem(key, JSON.stringify(value));
        },
		getLocalStorageObject(key) {
			return JSON.parse(localStorage.getItem(key));
        },
		clearLocalStorage(key) {
            localStorage.clear(key);
        },
        setSessionStorageString(key,value) {
            sessionStorage.setItem(key, value);
            let obj={};
            obj[key] = value
            window.dispatchEvent(new CustomEvent('session-storage-changed', {detail: obj}));
        },
		getSessionStorageString(key) {
			return sessionStorage.getItem(key);
        },
		setSessionStorageObject(key,value) {
            sessionStorage.setItem(key, JSON.stringify(value));
        },
		getSessionStorageObject(key) {
			return JSON.parse(sessionStorage.getItem(key));
        },
		clearSessionStorage(key) {
            sessionStorage.clear(key);
        }
	}
}